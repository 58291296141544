import * as d3Shape from "victory-vendor/d3-shape";
const defined = d => {
  const y = d._y1 !== undefined ? d._y1 : d._y;
  return y !== null && y !== undefined && d._y0 !== null;
};
const getXAccessor = scale => {
  return d => scale.x(d._x1 !== undefined ? d._x1 : d._x);
};
const getYAccessor = scale => {
  return d => scale.y(d._y1 !== undefined ? d._y1 : d._y);
};
const getAngleAccessor = scale => {
  return d => {
    const x = scale.x(d._x1 !== undefined ? d._x1 : d._x);
    return -1 * x + Math.PI / 2;
  };
};
const toNewName = interpolation => {
  // d3 shape changed the naming scheme for interpolators from "basis" -> "curveBasis" etc.
  const capitalize = s => s && s[0].toUpperCase() + s.slice(1);
  return `curve${capitalize(interpolation)}`;
};
const toNewNameClosed = interpolation => {
  return `${toNewName(interpolation)}Closed`;
};
export const getInterpolationFunction = props => {
  const {
    interpolation
  } = props;
  if (typeof interpolation === "function") {
    return interpolation;
  }
  if (typeof interpolation === "string") {
    const {
      polar,
      openCurve = !polar
    } = props;
    const interpolationName = !openCurve ? toNewNameClosed(interpolation) : toNewName(interpolation);
    return d3Shape[interpolationName];
  }
  return d3Shape.curveLinear;
};
export const getLineFunction = props => {
  const {
    polar,
    scale,
    horizontal
  } = props;
  return polar ? d3Shape.lineRadial().defined(defined).curve(getInterpolationFunction(props)).angle(getAngleAccessor(scale)).radius(getYAccessor(scale)) : d3Shape.line().defined(defined).curve(getInterpolationFunction(props)).x(horizontal ? getYAccessor(scale) : getXAccessor(scale)).y(horizontal ? getXAccessor(scale) : getYAccessor(scale));
};