"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = orderedReducer;
var _merge2 = _interopRequireDefault(require("lodash/fp/merge"));
var _isEqual2 = _interopRequireDefault(require("lodash/isEqual"));
var _keyBy2 = _interopRequireDefault(require("lodash/keyBy"));
var _map2 = _interopRequireDefault(require("lodash/map"));
var _omit2 = _interopRequireDefault(require("lodash/omit"));
var _reject2 = _interopRequireDefault(require("lodash/reject"));
var _unionBy2 = _interopRequireDefault(require("lodash/unionBy"));
var _get2 = _interopRequireDefault(require("lodash/get"));
var _size2 = _interopRequireDefault(require("lodash/size"));
var _constants = require("../constants");
var _reducers = require("../utils/reducers");
var _actionHandlers;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var DOCUMENT_ADDED = _constants.actionTypes.DOCUMENT_ADDED,
  GET_SUCCESS = _constants.actionTypes.GET_SUCCESS,
  LISTENER_RESPONSE = _constants.actionTypes.LISTENER_RESPONSE,
  CLEAR_DATA = _constants.actionTypes.CLEAR_DATA,
  DELETE_SUCCESS = _constants.actionTypes.DELETE_SUCCESS,
  DOCUMENT_REMOVED = _constants.actionTypes.DOCUMENT_REMOVED,
  DOCUMENT_MODIFIED = _constants.actionTypes.DOCUMENT_MODIFIED;
function newArrayWithItemMoved(collectionState, meta, ordered, newValue) {
  var doc = meta.doc;
  var _ref = ordered || {},
    oldIndex = _ref.oldIndex,
    newIndex = _ref.newIndex;
  var arrayWithoutItem = [].concat(_toConsumableArray(collectionState.slice(0, oldIndex)), _toConsumableArray(collectionState.slice(oldIndex + 1)));
  return [].concat(_toConsumableArray(arrayWithoutItem.slice(0, newIndex)), [_objectSpread({
    id: doc
  }, newValue) || _objectSpread({}, collectionState[oldIndex])], _toConsumableArray(arrayWithoutItem.slice(newIndex)));
}
function modifyDoc(collectionState, action) {
  if (action.payload.ordered) {
    var _action$payload$order = action.payload.ordered,
      newIndex = _action$payload$order.newIndex,
      oldIndex = _action$payload$order.oldIndex;
    if (newIndex !== null && oldIndex !== null && newIndex > -1 && oldIndex > -1 && newIndex !== oldIndex) {
      return newArrayWithItemMoved(collectionState, action.meta, action.payload.ordered, action.payload.data);
    }
  }
  if (!action.meta.subcollections || action.meta.storeAs) {
    return (0, _reducers.updateItemInArray)(collectionState, action.meta.doc, function (item) {
      return _objectSpread({
        id: action.meta.doc
      }, action.payload.data);
    });
  }
  var _pathToArr = (0, _reducers.pathToArr)(action.meta.path),
    _pathToArr2 = _slicedToArray(_pathToArr, 4),
    docId = _pathToArr2[1],
    subcollectionName = _pathToArr2[2],
    subDocId = _pathToArr2[3];
  return (0, _reducers.updateItemInArray)(collectionState, docId, function (item) {
    return _objectSpread(_objectSpread({}, item), {}, _defineProperty({}, subcollectionName, (0, _reducers.updateItemInArray)((0, _get2.default)(item, subcollectionName, []), subDocId, function (subitem) {
      return (0, _merge2.default)(subitem, action.payload.data);
    })));
  });
}
function addDoc() {
  var array = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var meta = action.meta,
    payload = action.payload;
  if (!meta.subcollections || meta.storeAs) {
    return [].concat(_toConsumableArray(array.slice(0, payload.ordered.newIndex)), [_objectSpread({
      id: meta.doc
    }, payload.data)], _toConsumableArray(array.slice(payload.ordered.newIndex)));
  }
  return modifyDoc(array, action);
}
function removeDoc(array, action) {
  if (!action.meta.subcollections || action.meta.storeAs) {
    return (0, _reject2.default)(array, {
      id: action.meta.doc
    });
  }
  var subcollectionSetting = action.meta.subcollections[0];
  if (!subcollectionSetting.doc) {
    return (0, _reducers.updateItemInArray)(array, action.meta.doc, function (item) {
      return (0, _omit2.default)(item, [subcollectionSetting.collection]);
    });
  }
  return (0, _reducers.updateItemInArray)(array, action.meta.doc, function (item) {
    var subcollectionVal = (0, _get2.default)(item, subcollectionSetting.collection, []);
    if (subcollectionVal.length) {
      return _objectSpread(_objectSpread({}, item), {}, _defineProperty({}, subcollectionSetting.collection, (0, _reject2.default)(array, {
        id: subcollectionSetting.doc
      })));
    }
    return item;
  });
}
function writeCollection(collectionState, action) {
  var meta = action.meta,
    _action$merge = action.merge,
    merge = _action$merge === void 0 ? {
      doc: true,
      collections: true
    } : _action$merge;
  if (meta.storeAs) {
    return action.payload.ordered;
  }
  var collectionStateSize = (0, _size2.default)(collectionState);
  var payloadExists = !!(0, _size2.default)(action.payload.ordered);
  if (meta.doc && merge.doc && collectionStateSize) {
    return modifyDoc(collectionState, action);
  }
  if (collectionStateSize && merge.collections) {
    if (!payloadExists) {
      return [];
    }
    var existingKeys = collectionState && (0, _keyBy2.default)(collectionState, 'id');
    return (0, _map2.default)(action.payload.ordered, function (newDocObj) {
      var existingDoc = (0, _get2.default)(existingKeys, newDocObj.id);
      return !!existingDoc && !(0, _isEqual2.default)(existingDoc, newDocObj) ? _objectSpread(_objectSpread({}, existingDoc), newDocObj) : newDocObj;
    });
  }
  if (meta.doc && meta.subcollections) {
    var subcollectionConfig = meta.subcollections[0];
    if (!collectionStateSize) {
      return [_defineProperty({
        id: meta.doc
      }, subcollectionConfig.collection, action.payload.ordered)];
    }
    return (0, _reducers.updateItemInArray)(collectionState, meta.doc, function (item) {
      return payloadExists ? _objectSpread(_objectSpread({}, item), {}, _defineProperty({}, subcollectionConfig.collection, (0, _unionBy2.default)((0, _get2.default)(item, subcollectionConfig.collection, []), action.payload.ordered, 'id'))) : (0, _omit2.default)(item, [subcollectionConfig.collection]);
    });
  }
  if (meta.doc && collectionStateSize) {
    if (!(0, _size2.default)(action.payload.ordered)) {
      return collectionState;
    }
    return (0, _reducers.updateItemInArray)(collectionState, meta.doc, function (item) {
      return (0, _merge2.default)(item, action.payload.ordered[0]);
    });
  }
  return action.payload.ordered;
}
var actionHandlers = (_actionHandlers = {}, _defineProperty(_actionHandlers, DOCUMENT_ADDED, addDoc), _defineProperty(_actionHandlers, DOCUMENT_MODIFIED, modifyDoc), _defineProperty(_actionHandlers, DOCUMENT_REMOVED, removeDoc), _defineProperty(_actionHandlers, DELETE_SUCCESS, removeDoc), _defineProperty(_actionHandlers, LISTENER_RESPONSE, writeCollection), _defineProperty(_actionHandlers, GET_SUCCESS, writeCollection), _actionHandlers);
var orderedCollectionReducer = (0, _reducers.createReducer)(undefined, actionHandlers);
function orderedReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  if (!action.type) {
    return state;
  }
  if (action.type === CLEAR_DATA) {
    if (action.preserve && action.preserve.ordered) {
      return (0, _reducers.preserveValuesFromState)(state, action.preserve.ordered, {});
    }
    return {};
  }
  if (!Object.prototype.hasOwnProperty.call(actionHandlers, action.type)) {
    return state;
  }
  if (!action.meta || !action.meta.storeAs && !action.meta.collection) {
    return state;
  }
  var storeUnderKey = action.meta.storeAs || action.meta.collection;
  var collectionStateSlice = (0, _get2.default)(state, storeUnderKey);
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, storeUnderKey, orderedCollectionReducer(collectionStateSlice, action)));
}