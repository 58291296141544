"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapWithFirebaseAndDispatch = mapWithFirebaseAndDispatch;
exports.wrapInDispatch = wrapInDispatch;
var _mapValues2 = _interopRequireDefault(require("lodash/mapValues"));
var _isObject2 = _interopRequireDefault(require("lodash/isObject"));
var _mutate = _interopRequireDefault(require("./mutate"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function makePayload(_ref, valToPass) {
  var payload = _ref.payload;
  return typeof payload === 'function' ? payload(valToPass) : payload;
}
function wrapInDispatch(dispatch, _ref2) {
  var ref = _ref2.ref,
    _ref2$meta = _ref2.meta,
    meta = _ref2$meta === void 0 ? {} : _ref2$meta,
    method = _ref2.method,
    _ref2$args = _ref2.args,
    args = _ref2$args === void 0 ? [] : _ref2$args,
    types = _ref2.types;
  if (typeof dispatch !== 'function') {
    throw new Error('dispatch is not a function');
  }
  var _types = _slicedToArray(types, 3),
    requestingType = _types[0],
    successType = _types[1],
    errorType = _types[2];
  var startAction = {
    type: (0, _isObject2.default)(requestingType) ? requestingType.type : requestingType,
    meta: meta,
    payload: (0, _isObject2.default)(requestingType) ? requestingType.payload : {
      args: args
    }
  };
  var optimistic = new Promise(function (resolve, reject) {
    Object.defineProperty(startAction, '_promise', {
      enumerable: false,
      configurable: false,
      writable: false,
      value: {
        resolve: resolve,
        reject: reject
      }
    });
    if (method !== 'mutate') {
      resolve();
    }
    dispatch(startAction);
  });
  var saved = method === 'mutate' ? _mutate.default.apply(void 0, [ref].concat(_toConsumableArray(args))) : ref[method].apply(ref, _toConsumableArray(args));
  saved.then(function (result) {
    var successIsObject = (0, _isObject2.default)(successType);
    var actionObj = {
      type: successIsObject ? successType.type : successType,
      meta: meta,
      payload: successIsObject && successType.payload ? makePayload(successType, result) : {
        args: args
      }
    };
    if (successIsObject && successType.preserve) {
      actionObj.preserve = successType.preserve;
    }
    if (successIsObject && successType.merge) {
      actionObj.merge = successType.merge;
    }
    dispatch(actionObj);
    return result;
  }).catch(function (err) {
    dispatch({
      type: errorType,
      meta: meta,
      payload: err
    });
    return Promise.reject(err);
  });
  return Promise.allSettled([saved, optimistic]).then(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      firestore = _ref4[0],
      memory = _ref4[1];
    if (memory.status === 'rejected') return Promise.reject(memory.reason);
    if (firestore.status === 'rejected') return Promise.reject(firestore.reason);
    return firestore.value;
  });
}
function createWithFirebaseAndDispatch(firebase, dispatch) {
  return function (func) {
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return func.apply(firebase, [firebase, dispatch].concat(args));
    };
  };
}
function mapWithFirebaseAndDispatch(firebase, dispatch, actions, aliases) {
  var withFirebaseAndDispatch = createWithFirebaseAndDispatch(firebase, dispatch);
  return _objectSpread(_objectSpread({}, (0, _mapValues2.default)(actions, withFirebaseAndDispatch)), aliases.reduce(function (acc, _ref5) {
    var action = _ref5.action,
      name = _ref5.name;
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, name, withFirebaseAndDispatch(action)));
  }, {}));
}