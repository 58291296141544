"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.default = void 0;
exports.deleteRef = deleteRef;
exports.get = get;
exports.mutate = mutate;
exports.runTransaction = runTransaction;
exports.set = set;
exports.setListener = setListener;
exports.setListeners = setListeners;
exports.unsetListener = unsetListener;
exports.unsetListeners = unsetListeners;
exports.update = update;
var _every2 = _interopRequireDefault(require("lodash/every"));
var _actions = require("../utils/actions");
var _constants = require("../constants");
var _query = require("../utils/query");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function add(firebase, dispatch, queryOption) {
  for (var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    args[_key - 3] = arguments[_key];
  }
  var meta = (0, _query.getQueryConfig)(queryOption);
  return (0, _actions.wrapInDispatch)(dispatch, {
    ref: (0, _query.firestoreRef)(firebase, meta),
    method: 'add',
    meta: meta,
    args: args,
    types: [_constants.actionTypes.ADD_REQUEST, {
      type: _constants.actionTypes.ADD_SUCCESS,
      payload: function payload(snap) {
        var obj = {
          id: snap.id,
          data: args[0]
        };
        _query.snapshotCache.set(obj, snap);
        return obj;
      }
    }, _constants.actionTypes.ADD_FAILURE]
  });
}
function set(firebase, dispatch, queryOption) {
  var meta = (0, _query.getQueryConfig)(queryOption);
  for (var _len2 = arguments.length, args = new Array(_len2 > 3 ? _len2 - 3 : 0), _key2 = 3; _key2 < _len2; _key2++) {
    args[_key2 - 3] = arguments[_key2];
  }
  return (0, _actions.wrapInDispatch)(dispatch, {
    ref: (0, _query.firestoreRef)(firebase, meta),
    method: 'set',
    meta: meta,
    args: args,
    types: [_constants.actionTypes.SET_REQUEST, _constants.actionTypes.SET_SUCCESS, _constants.actionTypes.SET_FAILURE]
  });
}
function get(firebase, dispatch, queryOption) {
  var meta = (0, _query.getQueryConfig)(queryOption);
  var _ref = firebase._.config || {},
    mergeOrdered = _ref.mergeOrdered,
    mergeOrderedDocUpdates = _ref.mergeOrderedDocUpdates,
    mergeOrderedCollectionUpdates = _ref.mergeOrderedCollectionUpdates;
  return (0, _actions.wrapInDispatch)(dispatch, {
    ref: (0, _query.firestoreRef)(firebase, meta),
    method: 'get',
    meta: meta,
    types: [_constants.actionTypes.GET_REQUEST, {
      type: _constants.actionTypes.GET_SUCCESS,
      payload: function payload(snap) {
        var _snap$metadata;
        return {
          data: (0, _query.dataByIdSnapshot)(snap),
          ordered: (0, _query.orderedFromSnap)(snap),
          fromCache: typeof ((_snap$metadata = snap.metadata) === null || _snap$metadata === void 0 ? void 0 : _snap$metadata.fromCache) === 'boolean' ? snap.metadata.fromCache : true
        };
      },
      merge: {
        docs: mergeOrdered && mergeOrderedDocUpdates,
        collections: mergeOrdered && mergeOrderedCollectionUpdates
      }
    }, _constants.actionTypes.GET_FAILURE]
  });
}
function update(firebase, dispatch, queryOption) {
  var meta = (0, _query.getQueryConfig)(queryOption);
  for (var _len3 = arguments.length, args = new Array(_len3 > 3 ? _len3 - 3 : 0), _key3 = 3; _key3 < _len3; _key3++) {
    args[_key3 - 3] = arguments[_key3];
  }
  return (0, _actions.wrapInDispatch)(dispatch, {
    ref: (0, _query.firestoreRef)(firebase, meta),
    method: 'update',
    meta: meta,
    args: args,
    types: [_constants.actionTypes.UPDATE_REQUEST, _constants.actionTypes.UPDATE_SUCCESS, _constants.actionTypes.UPDATE_FAILURE]
  });
}
function deleteRef(firebase, dispatch, queryOption) {
  var meta = (0, _query.getQueryConfig)(queryOption);
  var config = firebase._.config;
  if (!meta.doc || meta.subcollections && !(0, _every2.default)(meta.subcollections, 'doc')) {
    if (typeof config.onAttemptCollectionDelete === 'function') {
      return config.onAttemptCollectionDelete(queryOption, dispatch, firebase);
    }
    return Promise.reject(new Error('Only documents can be deleted.'));
  }
  return (0, _actions.wrapInDispatch)(dispatch, {
    ref: (0, _query.firestoreRef)(firebase, meta),
    method: 'delete',
    meta: meta,
    types: [_constants.actionTypes.DELETE_REQUEST, {
      type: _constants.actionTypes.DELETE_SUCCESS,
      preserve: firebase._.config.preserveOnDelete
    }, _constants.actionTypes.DELETE_FAILURE]
  });
}
function setListener(firebase, dispatch, queryOpts, successCb, errorCb) {
  var meta = (0, _query.getQueryConfig)(queryOpts);
  var success = function success(docData) {
    if (!meta.populates) {
      (0, _query.dispatchListenerResponse)({
        dispatch: dispatch,
        docData: docData,
        meta: meta,
        firebase: firebase
      });
      if (typeof successCb === 'function') successCb(docData);
      return Promise.resolve();
    }
    return (0, _query.getPopulateActions)({
      firebase: firebase,
      docData: docData,
      meta: meta
    }).then(function (populateActions) {
      populateActions.forEach(function (populateAction) {
        dispatch(_objectSpread(_objectSpread({}, populateAction), {}, {
          type: _constants.actionTypes.LISTENER_RESPONSE,
          timestamp: Date.now()
        }));
      });
      (0, _query.dispatchListenerResponse)({
        dispatch: dispatch,
        docData: docData,
        meta: meta,
        firebase: firebase
      });
    }).catch(function (populateErr) {
      var _ref2 = firebase._.config || {},
        logListenerError = _ref2.logListenerError;
      if (logListenerError !== false) {
        if (logListenerError !== false && !!console && typeof console.error === 'function') {
          console.error('redux-firestore error populating:', populateErr);
        }
      }
      if (typeof errorCb === 'function') errorCb(populateErr);
    });
  };
  var error = function error(err) {
    var _ref3 = firebase._.config || {},
      mergeOrdered = _ref3.mergeOrdered,
      mergeOrderedDocUpdates = _ref3.mergeOrderedDocUpdates,
      mergeOrderedCollectionUpdates = _ref3.mergeOrderedCollectionUpdates,
      logListenerError = _ref3.logListenerError,
      preserveOnListenerError = _ref3.preserveOnListenerError;
    if (logListenerError !== false && !!console && typeof console.error === 'function') {
      console.error('redux-firestore listener error:', err);
    }
    dispatch({
      type: _constants.actionTypes.LISTENER_ERROR,
      meta: meta,
      payload: err,
      merge: {
        docs: mergeOrdered && mergeOrderedDocUpdates,
        collections: mergeOrdered && mergeOrderedCollectionUpdates
      },
      preserve: preserveOnListenerError
    });
    if (typeof errorCb === 'function') errorCb(err);
  };
  var includeMetadataChanges = queryOpts && queryOpts.includeMetadataChanges || false;
  var unsubscribe = includeMetadataChanges ? (0, _query.firestoreRef)(firebase, meta).onSnapshot({
    includeMetadataChanges: includeMetadataChanges
  }, success, error) : (0, _query.firestoreRef)(firebase, meta).onSnapshot(success, error);
  (0, _query.attachListener)(firebase, dispatch, meta, unsubscribe);
  return unsubscribe;
}
function setListeners(firebase, dispatch, listeners) {
  if (!Array.isArray(listeners)) {
    throw new Error('Listeners must be an Array of listener configs (Strings/Objects).');
  }
  var config = firebase._.config;
  if (config.oneListenerPerPath) {
    listeners.forEach(function (listener) {
      var path = (0, _query.getQueryName)(listener);
      var oldListenerCount = firebase._.pathListenerCounts[path] || 0;
      firebase._.pathListenerCounts[path] = oldListenerCount + 1;
      if (oldListenerCount > 0) {
        return;
      }
      setListener(firebase, dispatch, listener);
    });
  } else {
    var allowMultipleListeners = config.allowMultipleListeners;
    listeners.forEach(function (listener) {
      var path = (0, _query.getQueryName)(listener);
      var oldListenerCount = firebase._.pathListenerCounts[path] || 0;
      var multipleListenersEnabled = typeof allowMultipleListeners === 'function' ? allowMultipleListeners(listener, firebase._.listeners) : allowMultipleListeners;
      firebase._.pathListenerCounts[path] = oldListenerCount + 1;
      if (oldListenerCount === 0 || multipleListenersEnabled) {
        setListener(firebase, dispatch, listener);
      }
    });
  }
}
function unsetListener(firebase, dispatch, meta) {
  return (0, _query.detachListener)(firebase, dispatch, (0, _query.getQueryConfig)(meta));
}
function unsetListeners(firebase, dispatch, listeners) {
  if (!Array.isArray(listeners)) {
    throw new Error('Listeners must be an Array of listener configs (Strings/Objects).');
  }
  var config = firebase._.config;
  var allowMultipleListeners = config.allowMultipleListeners;
  listeners.forEach(function (listener) {
    var path = (0, _query.getQueryName)(listener);
    var listenerExists = firebase._.pathListenerCounts[path] >= 1;
    var multipleListenersEnabled = typeof allowMultipleListeners === 'function' ? allowMultipleListeners(listener, firebase._.listeners) : allowMultipleListeners;
    if (listenerExists) {
      firebase._.pathListenerCounts[path] -= 1;
      if (firebase._.pathListenerCounts[path] === 0 || multipleListenersEnabled) {
        unsetListener(firebase, dispatch, listener);
      }
    }
  });
}
function runTransaction(firebase, dispatch, transactionPromise) {
  return (0, _actions.wrapInDispatch)(dispatch, {
    ref: firebase.firestore(),
    method: 'runTransaction',
    args: [transactionPromise],
    types: [_constants.actionTypes.TRANSACTION_START, _constants.actionTypes.TRANSACTION_SUCCESS, _constants.actionTypes.TRANSACTION_FAILURE]
  });
}
function mutate(firebase, dispatch, mutations) {
  var timestamp = "".concat(+new Date());
  return (0, _actions.wrapInDispatch)(dispatch, {
    ref: firebase,
    method: 'mutate',
    meta: {
      timestamp: timestamp
    },
    args: [mutations],
    types: [{
      type: _constants.actionTypes.MUTATE_START,
      payload: {
        data: mutations
      }
    }, _constants.actionTypes.MUTATE_SUCCESS, {
      type: _constants.actionTypes.MUTATE_FAILURE,
      meta: {
        timestamp: timestamp
      },
      payload: {
        data: mutations
      }
    }]
  });
}
var _default = {
  get: get,
  firestoreRef: _query.firestoreRef,
  add: add,
  update: update,
  setListener: setListener,
  setListeners: setListeners,
  unsetListener: unsetListener,
  unsetListeners: unsetListeners,
  runTransaction: runTransaction,
  mutate: mutate
};
exports.default = _default;