/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Comfortaa_300Light = require('./Comfortaa_300Light.ttf');
export const Comfortaa_400Regular = require('./Comfortaa_400Regular.ttf');
export const Comfortaa_500Medium = require('./Comfortaa_500Medium.ttf');
export const Comfortaa_600SemiBold = require('./Comfortaa_600SemiBold.ttf');
export const Comfortaa_700Bold = require('./Comfortaa_700Bold.ttf');
