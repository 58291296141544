"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.combineReducers = combineReducers;
exports.createReducer = createReducer;
exports.getDotStrPath = getDotStrPath;
exports.getSlashStrPath = getSlashStrPath;
exports.pathFromMeta = pathFromMeta;
exports.pathToArr = pathToArr;
exports.preserveValuesFromState = preserveValuesFromState;
exports.updateItemInArray = updateItemInArray;
var _flatten2 = _interopRequireDefault(require("lodash/flatten"));
var _trimStart2 = _interopRequireDefault(require("lodash/trimStart"));
var _replace2 = _interopRequireDefault(require("lodash/replace"));
var _pick2 = _interopRequireDefault(require("lodash/pick"));
var _isBoolean2 = _interopRequireDefault(require("lodash/isBoolean"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function pathToArr(path) {
  return path ? path.split(/\//).filter(function (p) {
    return !!p;
  }) : [];
}
function getSlashStrPath(path) {
  return (0, _trimStart2.default)((0, _replace2.default)(path, /[.]/g, '/'), '/');
}
function getDotStrPath(path) {
  return pathToArr(path).join('.');
}
function combineReducers(reducers) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return Object.keys(reducers).reduce(function (nextState, key) {
      nextState[key] = reducers[key](state[key], action);
      return nextState;
    }, {});
  };
}
function pathFromMeta(meta) {
  if (!meta) {
    throw new Error('Action meta is required to build path for reducers.');
  }
  var collection = meta.collection,
    collectionGroup = meta.collectionGroup,
    doc = meta.doc,
    subcollections = meta.subcollections,
    storeAs = meta.storeAs;
  if (storeAs) {
    return doc ? [storeAs, doc] : [storeAs];
  }
  if (meta.path) {
    return meta.path.split('/');
  }
  if (!collection && !collectionGroup) {
    throw new Error('Collection or Collection Group is required to construct reducer path.');
  }
  var basePath = [collection || collectionGroup];
  if (doc) {
    basePath = [].concat(_toConsumableArray(basePath), [doc]);
  }
  if (!subcollections) {
    return basePath;
  }
  var mappedCollections = subcollections.map(pathFromMeta);
  return [].concat(_toConsumableArray(basePath), _toConsumableArray((0, _flatten2.default)(mappedCollections)));
}
function updateItemInArray(array, itemId, updateItemCallback) {
  var matchFound = false;
  var modified = Array.isArray(array) ? array.map(function (item) {
    if (!item || item.id !== itemId) {
      return item;
    }
    matchFound = true;
    var updatedItem = updateItemCallback(item);
    return updatedItem;
  }) : [];
  if (!matchFound) {
    modified.push(updateItemCallback({
      id: itemId
    }));
  }
  return modified;
}
function createReducer(initialState, handlers) {
  return function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}
function preserveValuesFromState(state, preserveSetting, nextState) {
  if ((0, _isBoolean2.default)(preserveSetting)) {
    return nextState ? _objectSpread(_objectSpread({}, state), nextState) : state;
  }
  if (typeof preserveSetting === 'function') {
    return preserveSetting(state, nextState);
  }
  if (Array.isArray(preserveSetting)) {
    return (0, _pick2.default)(state, preserveSetting);
  }
  throw new Error('Invalid preserve parameter. It must be an Object or an Array.');
}